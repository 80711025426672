import React, { useEffect } from 'react';
import { StyleRegister } from '../components/sections/RegisterForm/index.styled';
import Nav from '@components/common/Nav';
import Layout from '@layouts/index';
import Footer from '@components/sections/Footer';
//@ts-ignore
import { Helmet } from 'react-helmet';

const RegisterForm: React.FC = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/shell.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      // @ts-ignore
      if (window.hbspt) {
        // @ts-ignore
        window.hbspt.forms.create({
          portalId: '20960802',
          region: 'na1',
          formId: '37cf2d84-2073-48c5-a72f-4eb91fc0375c',
          target: '#hubspotForm',
          version: 'V2_PRERELEASE',
        });
      }
    });
  }, []);

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="BubaJobs | Portal de empleo en Argentina"
        />
        <title>BubaJobs | Portal de empleo en Argentina</title>
        <link rel="canonical" href="http://bubajobs.com.ar/" />
      </Helmet>
      <Nav activeSection="none" />
      <StyleRegister>
        <div>
          <h1>Eliminar cuenta</h1>
          <div className="form-paragraph">
            <p>
              Te contactaremos a la brevedad 
            </p>
          </div>
        </div>
        <div id="hubspotForm"></div>
      </StyleRegister>
      <Footer />
    </Layout>
  );
};

export default RegisterForm;
